<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-card class="blog-edit-wrapper">

        <validation-observer
            ref="refFormObserver"
        >
          <!-- form -->
          <b-form
              class="mt-2"
          >
            <b-row>
              <b-col cols="12" class="mb-1" md="12">
                <h1>Create Blog</h1>
              </b-col>
              <b-col md="4">
                <b-form-group
                    class="mb-2 mt-25"
                    label="Title"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-input
                        id="blog-edit-title"
                        v-model="blog.Title"
                        :state="errors.length > 0 ? false:null"
                    />
                    <!--                  <small class="text-danger mt-3">{{ errors[0] }}</small>-->
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    class="mb-2"
                    label="Game"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                  <v-select
                      v-if="games"
                      v-model="blog.GameId"
                      :options="games"
                      :class="{'border-danger rounded':errors.length > 0 }"
                      :reduce="name => name.id"
                      label="name"
                  />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    class="mb-2"
                    label="What Kind Of Blog Is This ?"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                  <v-select
                      v-if="blogTypes"
                      v-model="blog.BlogTypes"
                      :options="blogTypes"
                      :class="{'border-danger rounded':errors.length > 0 }"
                      :reduce="name => name.value"
                      label="name"
                  />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
              >
                <b-form-group
                    label="Summary"
                    label-for="summary"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-textarea
                        id="summary"
                        v-model="blog.ShortDescription"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    class="mb-2"
                    label="Content"
                    label-for="blog-content"
                >
                  <ckEditorMain ref="editorContent" :defaultContent="blog.FullDescription"
                                @getEditorContent="setEditorContent"
                  ></ckEditorMain>
                </b-form-group>
              </b-col>

              <b-col
                  class="mb-2"
                  cols="12"
              >
                <div class="border rounded p-2">
                  <h4 class="mb-1">
                    Blog Picture
                  </h4>
                  <MediaHandler ref="mediaHandler" @sendData="setMainPicture"></MediaHandler>
                </div>
              </b-col>
              <b-col
                  class="mt-50"
                  cols="12"
              >

              </b-col>
            </b-row>
          </b-form>
          <!--/ form -->
          <b-card>
            <b-row>
              <b-col cols="12" md="12">
                <h2>SEO</h2>
              </b-col>
              <b-col class="" md="4">
                <b-form-group
                    label="Seo Title"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        v-model="blog.SeoTitle"
                        :state="errors.length > 0 ? false:null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="" md="4">
                <b-form-group
                    label="Seo Url"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        v-model="allCharactersToDash"
                        :state="errors.length > 0 ? false:null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="" cols="12">
                <b-form-group
                    label="Seo Description"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >

                    <b-form-textarea
                        id="data-edit-seoTitle"
                        v-model="blog.SeoDescription"
                        :state="errors.length > 0 ? false:null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="pl-1">
                <div class="col-md-12 ">
                  <!--- serp Desktop --->
                  <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                    <div class="serp-title">
                      <h4 style="color: rgb(26, 13, 171)!important">
                        {{ blog.SeoTitle }}
                      </h4>
                    </div>
                    <div class="serp-url">
                      <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                        <span>https://skycoach.com/<span class="serp-arrow"></span></span>
                        <span style="overflow-wrap: anywhere">
                      {{ blog.SeoUrl.replaceAll(' ', '-') }}
                    </span>

                      </h5>
                    </div>
                    <div class="serp-description">
                      <h6 style="overflow-wrap: anywhere">
                        {{ blog.SeoDescription }}
                      </h6>
                    </div>
                  </div>
                  <!--- serp Mobile --->
                  <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                    <div class="serp-title">
                      <h6>
                        {{ blog.SeoTitle }}
                      </h6>
                    </div>
                    <div class="serp-url d-flex align-items-center flex-wrap">

                      <small>{{ blog.SeoUrl.replaceAll(' ', '-') }}/</small>
                      <small>
                        https://skycoach.com/
                        <small class="serp-arrow"></small>
                      </small>
                    </div>
                    <div class="serp-description">
                      <small>
                        {{ blog.SeoDescription }}
                      </small>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <b-button
                    class="btn-tour-skip mr-1"
                    variant="primary"
                    @click="createBlog"
                >
                  <span class="mr-25 align-middle">Submit</span>

                </b-button>
              </b-col>
            </b-row>
          </b-card>

        </validation-observer>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { BlogCreateBlogRequest } from '@/libs/Api/Blog'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ckEditorMain from '@/views/components/ckEditorMain'
import { GetAllGames } from '@/libs/Api/games'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import Helper from '@/libs/Helper'

export default {
  name: 'BlogCreate',
  title: 'create blog',
  data() {
    return {
      required,
      blogCategories: null,
      base64ImageSrc: null,
      overlay: false,
      games: null,
      blog: {
        GameId:null,
        Title: '',
        ShortDescription: '',
        FullDescription: '',
        SeoTitle: '',
        SeoDescription: '',
        SeoUrl: '',
        ImageFile: null,
        Thumbnail: null,
        BlogTypes: null,

        // 1 guides
        // 2 class guides
        // 3 general guides
      },
      blogTypes: [
        {
          name: 'Guides',
          value: 1
        },
        {
          name: 'Class Guides',
          value: 2
        },
        {
          name: 'General Guides',
          value: 3
        }
      ]
    }
  },
  async mounted() {
    await Promise.all([
      this.getGames()
    ])
  },
  computed: {
    currentDate() {
      let today = new Date()
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      return date + ' ' + time
    },
    allCharactersToDash: {
      get: function () {
        return this.blog.SeoUrl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.blog.SeoUrl = newValue
          // Remove all characters that are NOT number
          this.blog.SeoUrl = this.blog.SeoUrl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.blog.SeoUrl = this.blog.SeoUrl.replaceAll(' ', '-')
        } else if (!newValue || this.blog.SeoUrl === '') {
          this.blog.SeoUrl = null
        }
      },
    },
  },
  methods: {
    setMainPicture(data) {
      this.blog.ImageFile = data
    },

    async createBlog() {
      let _this = this
      Helper.validateForm(_this, 'refFormObserver', async () => {
        _this.overlay = true
        let blogCreateRequest = new BlogCreateBlogRequest(_this)
        _this.$refs.mediaHandler.sendFiles()
        let myForm = Helper.makeFormData(_this.blog)
        blogCreateRequest.setParams(myForm)
        await blogCreateRequest.sendFormData(function (content) {
          _this.overlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Done`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `Blog created.`,
            },
          })
          _this.$router.push('/pages/blog/list')
        }, function (error) {
          _this.overlay = false
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        })
      })
    },

    setEditorContent(content) {
      this.blog.FullDescription = content
    },

    async getGames() {
      let _this = this
      _this.showOverlay = true
      let getAllGames = new GetAllGames(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        search: ''
      }
      getAllGames.setParams(data)
      await getAllGames.fetch(function (content) {
        _this.games = content.data.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

  },
  components: {
    MediaHandler,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BOverlay,
    vSelect,
    quillEditor,
    ckEditorMain,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
