var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.overlay,"rounded":"sm"}},[_c('div',[_c('b-card',{staticClass:"blog-edit-wrapper"},[_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"12"}},[_c('h1',[_vm._v("Create Blog")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2 mt-25",attrs:{"label":"Title"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title","state":errors.length > 0 ? false:null},model:{value:(_vm.blog.Title),callback:function ($$v) {_vm.$set(_vm.blog, "Title", $$v)},expression:"blog.Title"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Game"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.games)?_c('v-select',{class:{'border-danger rounded':errors.length > 0 },attrs:{"options":_vm.games,"reduce":function (name) { return name.id; },"label":"name"},model:{value:(_vm.blog.GameId),callback:function ($$v) {_vm.$set(_vm.blog, "GameId", $$v)},expression:"blog.GameId"}}):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"What Kind Of Blog Is This ?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.blogTypes)?_c('v-select',{class:{'border-danger rounded':errors.length > 0 },attrs:{"options":_vm.blogTypes,"reduce":function (name) { return name.value; },"label":"name"},model:{value:(_vm.blog.BlogTypes),callback:function ($$v) {_vm.$set(_vm.blog, "BlogTypes", $$v)},expression:"blog.BlogTypes"}}):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Summary","label-for":"summary"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"summary","state":errors.length > 0 ? false:null,"rows":"2"},model:{value:(_vm.blog.ShortDescription),callback:function ($$v) {_vm.$set(_vm.blog, "ShortDescription", $$v)},expression:"blog.ShortDescription"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Content","label-for":"blog-content"}},[_c('ckEditorMain',{ref:"editorContent",attrs:{"defaultContent":_vm.blog.FullDescription},on:{"getEditorContent":_vm.setEditorContent}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Blog Picture ")]),_c('MediaHandler',{ref:"mediaHandler",on:{"sendData":_vm.setMainPicture}})],1)]),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}})],1)],1),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',[_vm._v("SEO")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Seo Title"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.blog.SeoTitle),callback:function ($$v) {_vm.$set(_vm.blog, "SeoTitle", $$v)},expression:"blog.SeoTitle"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Seo Url"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.allCharactersToDash),callback:function ($$v) {_vm.allCharactersToDash=$$v},expression:"allCharactersToDash"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Seo Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"data-edit-seoTitle","state":errors.length > 0 ? false:null},model:{value:(_vm.blog.SeoDescription),callback:function ($$v) {_vm.$set(_vm.blog, "SeoDescription", $$v)},expression:"blog.SeoDescription"}})]}}])})],1)],1),_c('b-col',{staticClass:"pl-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"col-md-12 "},[_c('div',{staticClass:"serp-preview d-lg-flex d-none flex-column align-items-start"},[_c('div',{staticClass:"serp-title"},[_c('h4',{staticStyle:{"color":"rgb(26, 13, 171)!important"}},[_vm._v(" "+_vm._s(_vm.blog.SeoTitle)+" ")])]),_c('div',{staticClass:"serp-url"},[_c('h5',{staticClass:"d-flex align-items-center flex-wrap",staticStyle:{"color":"rgb(0, 102, 33)!important"}},[_c('span',[_vm._v("https://skycoach.com/"),_c('span',{staticClass:"serp-arrow"})]),_c('span',{staticStyle:{"overflow-wrap":"anywhere"}},[_vm._v(" "+_vm._s(_vm.blog.SeoUrl.replaceAll(' ', '-'))+" ")])])]),_c('div',{staticClass:"serp-description"},[_c('h6',{staticStyle:{"overflow-wrap":"anywhere"}},[_vm._v(" "+_vm._s(_vm.blog.SeoDescription)+" ")])])]),_c('div',{staticClass:"serp-preview d-lg-none  p-1 d-flex flex-column align-items-start"},[_c('div',{staticClass:"serp-title"},[_c('h6',[_vm._v(" "+_vm._s(_vm.blog.SeoTitle)+" ")])]),_c('div',{staticClass:"serp-url d-flex align-items-center flex-wrap"},[_c('small',[_vm._v(_vm._s(_vm.blog.SeoUrl.replaceAll(' ', '-'))+"/")]),_c('small',[_vm._v(" https://skycoach.com/ "),_c('small',{staticClass:"serp-arrow"})])]),_c('div',{staticClass:"serp-description"},[_c('small',[_vm._v(" "+_vm._s(_vm.blog.SeoDescription)+" ")])])])])]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-button',{staticClass:"btn-tour-skip mr-1",attrs:{"variant":"primary"},on:{"click":_vm.createBlog}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Submit")])])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }